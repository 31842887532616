<template>
  <div class="auth-wrapper bg-light-info auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <b-img
            :src="appLogoFull"
            height="70"
          />
        </b-link>

        <b-card-title class="mb-2 text-center">
          {{ $t('login.newPasswordConfirm') }} 🔒
        </b-card-title>

        <!-- form -->
        <validation-observer ref="refFormObserver">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- password -->
            <b-form-group
              :label="`${$t('login.newPassword')} (*)`"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:6"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="········"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              :label="`${$t('login.passwordConfirm')} (*)`"
              label-for="reset-password-confirm"
            >
              <validation-provider
                #default="{ errors }"
                name="confirmation"
                rules="required|min:6|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- <b-card-text class="mb-2">
              {{ $t('login.requiredNewPassword_1') }}
              <li>{{ $t('login.requiredNewPassword_2') }}</li>
              <li>{{ $t('login.requiredNewPassword_3') }}</li>
              <li>{{ $t('login.requiredNewPassword_4') }}</li>
            </b-card-text> -->
            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              {{ $t('login.setNewPassword') }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login-v1'}">
            <feather-icon icon="ChevronLeftIcon" />
            {{ $t('login.backToLogin') }}
          </b-link>
        </b-card-text>
      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle,
  BCardText, BImg,
  BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import {
  ref, computed, onUnmounted, onMounted,
} from '@vue/composition-api'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import formValidation from '@core/comp-functions/forms/form-validation'

import { required } from '@validations'
import useToast from '@useToast'
import { $themeConfig } from '@themeConfig'

import authStoreModule from './authStoreModule'

export default {
  components: {
    BCard,
    BImg,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,

  },
  setup() {
    const { appLogoFull: defaultLogo } = $themeConfig.app
    const appLogoFull = computed(() => store.getters['app/getLogo']).value || defaultLogo
    const AUTH_APP_STORE_MODULE_NAME = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP_STORE_MODULE_NAME)) {
      store.registerModule(AUTH_APP_STORE_MODULE_NAME, authStoreModule)
    }
    // eslint-disable-next-line no-unused-vars
    const { router } = useRouter()
    const changePwKey = computed(() => store.getters['app-auth/getChangePwKey'])
    const username = computed(() => store.getters['app-auth/getUsername'])

    onMounted(() => {
      if (!changePwKey.value || !username.value) {
        router.go(-1)
      }
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTH_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(AUTH_APP_STORE_MODULE_NAME)
      }
    })
    const userEmail = ref(null)
    const cPassword = ref(null)
    const password = ref(null)

    // Toggle Password
    const password1FieldType = ref('password')
    const password2FieldType = ref('password')

    const { toastSuccess, toastError } = useToast()

    const { refFormObserver } = formValidation()
    // Computed
    const password1ToggleIcon = computed(() => (password1FieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
    const password2ToggleIcon = computed(() => (password2FieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))

    // Methods
    function togglePassword1Visibility() {
      password1FieldType.value = password1FieldType.value === 'password' ? 'text' : 'password'
    }
    function togglePassword2Visibility() {
      password2FieldType.value = password2FieldType.value === 'password' ? 'text' : 'password'
    }
    function validationForm() {
      this.$bvModal.show('modal-api-loading')
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            store.dispatch('app-auth/resetPassword', {
              changePwKey: changePwKey.value,
              username: username.value,
              newPassword: password.value,
            })
              .then(() => {
                toastSuccess({
                  title: 'messagesList.success',
                  content: 'messagesList.auth.passwordChanged',
                })
                router.push({ name: 'auth-login' })
              })
              .catch(error => {
                toastError({
                  title: 'messagesList.error',
                  content: this.$t(`messagesList.auth.${error?.response?.data?.message || error.message || error}`),
                })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          } else {
          // validate toast message error
            const { errors } = this.$refs.refFormObserver
            Object.keys(errors).forEach(key => {
              errors[key].forEach(msg => {
                toastError({
                  title: 'messagesList.error',
                  content: msg,
                })
              })
            })
          }
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }

    return {
      userEmail,
      cPassword,
      password,
      // validation
      required,

      refFormObserver,

      // Toggle Password
      password1FieldType,
      password2FieldType,

      // Computed
      password1ToggleIcon,
      password2ToggleIcon,

      // Methods
      togglePassword1Visibility,
      togglePassword2Visibility,
      validationForm,
      appLogoFull,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
